import { cn } from "@site/src/utils";
import { Avatar } from "antd";

interface UserAvatarProps {
  value?: User;
  className?: string;
  size?: "large" | "small" | "default" | number;
}

export function UserAvatar({ value, ...props }: UserAvatarProps) {
  let hasIcon = value?.avatar && value?.avatar !== "";
  const { size = "small", className = "" } = props;
  return hasIcon ? (
    <Avatar
      className={cn("rounded-full object-cover", className)}
      src={value?.avatar}
      size={size}
    ></Avatar>
  ) : (
    <Avatar
      className={cn("text-white mx-1", className)}
      size={size}
      style={{ backgroundColor: "#7f56d9" }}
    >
      {value?.name ? value?.name[0].toUpperCase() : ""}
    </Avatar>
  );
  // <Avatar {...props}>
  //   X
  //   {/* <AvatarImage className="bg-white" alt="Picture" src={value?.avatar} /> */}
  //   {/* <AvatarFallback
  //     title={value?.name || ""}
  //     className={cn(fallbackClassName, "align-middle")}
  //   >
  //     <span className="sr-only">{value?.name || "-"}</span>
  //     {value && value.name?.[0] ? (
  //       value.name.trim()[0]
  //     ) : (
  //       <Icons.User className="h-4 w-4 " />
  //     )}
  //   </AvatarFallback> */}
  // </Avatar>
}
