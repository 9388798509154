import { LucideProps } from "lucide-react";

export function IconLogoBlack({ ...props }: LucideProps) {
  return (
    <svg  {...props} width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Default bot icon 4">
      <path id="Rectangle 37" d="M0 16C0 7.16344 7.16344 0 16 0H48C56.8366 0 64 7.16344 64 16V48C64 56.8366 56.8366 64 48 64H16C7.16344 64 0 56.8366 0 48V16Z" fill="#101828"/>
      <g id="Union">
      <path d="M11.4288 27.4285V17.1428H22.8574V21.7143H16.0002V42.2857H22.8574V46.8571H11.4288V36.5714H9.14307V27.4285H11.4288Z" fill="url(#paint0_linear_947_20875)"/>
      <path d="M41.1431 17.1428V21.7143H48.0002V42.2857H41.1431V46.8571H52.5716V36.5714H54.8574V27.4285H52.5716V17.1428H41.1431Z" fill="url(#paint1_linear_947_20875)"/>
      <path d="M22.8574 25.1428H27.4288V32H22.8574V25.1428Z" fill="url(#paint2_linear_947_20875)"/>
      <path d="M36.5716 36.5714H27.4288V40H36.5716V36.5714Z" fill="url(#paint3_linear_947_20875)"/>
      <path d="M36.5716 25.1428H41.1431V32H36.5716V25.1428Z" fill="url(#paint4_linear_947_20875)"/>
      </g>
      </g>
      <defs>
      <linearGradient id="paint0_linear_947_20875" x1="36.5716" y1="17.1428" x2="32.3152" y2="47.5413" gradientUnits="userSpaceOnUse">
      <stop stopColor="#7482FF"/>
      <stop offset="1" stopColor="#FA6198"/>
      </linearGradient>
      <linearGradient id="paint1_linear_947_20875" x1="36.5716" y1="17.1428" x2="32.3152" y2="47.5413" gradientUnits="userSpaceOnUse">
      <stop stopColor="#7482FF"/>
      <stop offset="1" stopColor="#FA6198"/>
      </linearGradient>
      <linearGradient id="paint2_linear_947_20875" x1="36.5716" y1="17.1428" x2="32.3152" y2="47.5413" gradientUnits="userSpaceOnUse">
      <stop stopColor="#7482FF"/>
      <stop offset="1" stopColor="#FA6198"/>
      </linearGradient>
      <linearGradient id="paint3_linear_947_20875" x1="36.5716" y1="17.1428" x2="32.3152" y2="47.5413" gradientUnits="userSpaceOnUse">
      <stop stopColor="#7482FF"/>
      <stop offset="1" stopColor="#FA6198"/>
      </linearGradient>
      <linearGradient id="paint4_linear_947_20875" x1="36.5716" y1="17.1428" x2="32.3152" y2="47.5413" gradientUnits="userSpaceOnUse">
      <stop stopColor="#7482FF"/>
      <stop offset="1" stopColor="#FA6198"/>
      </linearGradient>
      </defs>
      </svg>
  );
}